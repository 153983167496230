export const ROLE = {
  ADMIN: 'Admin',
  USER: 'User',
};

export const STORAGE_KEY = {
  TOKEN: 'token',
  OTP_TOKEN: 'token_otp', // force to use new OTP_TOKEN
};

export const LOCALE = {
  VIETNAMESE: 'vi',
  ENGLISH: 'en',
};

export const LOCALES = [
  {
    id: LOCALE.VIETNAMESE,
    name: `locale.${LOCALE.VIETNAMESE}`,
  },
  {
    id: LOCALE.ENGLISH,
    name: `locale.${LOCALE.ENGLISH}`,
  },
];

export const validateMessages = {
  [LOCALE.ENGLISH]: {
    required: '{label} is required',
  },
  [LOCALE.VIETNAMESE]: {
    required: '{label} bắt buộc',
  },
};

export const DEFAULT_PAGINATION = {
  PAGE: 1,
  LIMIT: 10,
};

export const TEXT_DEFAULT = {
  NA: 'N/A',
};

export const DATE_FORMAT = 'MM/DD/YYYY';

export const CHART_FILTER = ['year', 'month', 'week'];

export const SIDEBAR_MAX_WIDTH = 280;
export const SIDEBAR_MIN_WIDTH = 80;
export const INPUT_DYNAMIC_WIDTH = 200;
export const EXPORT_MODAL_WIDTH = 730;
export const AFFIX_HEIGHT = 48;

export const SIDER_WIDTH = 240;
export const SIDER_COLLAPSED_WIDTH = 80;

export const MODAL_WIDTH = {
  small: 394,
  medium: 766,
  large: 1024,
};

export const INPUT_SEARCH_WIDTH = 180;
export const SELECT_SEARCH_WIDTH = 180;
