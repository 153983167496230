/* eslint-disable react/react-in-jsx-scope */
import Icon from '@ant-design/icons';

import PieChartSVG from '../svg/pie-chart.svg?react';
import InboxSVG from '../svg/inbox.svg?react';
import LayoutSVG from '../svg/layout.svg?react';
import PeopleSVG from '../svg/people.svg?react';
import LogSVG from '../svg/log.svg?react';
import SettingSVG from '../svg/setting.svg?react';
import ArrowHeadLeftSVG from '../svg/arrow-head-left.svg?react';
import UploadSVG from '../svg/upload.svg?react';
import DownloadSVG from '../svg/download.svg?react';
import CodeDownloadSVG from '../svg/code-download.svg?react';
import InfoSVG from '../svg/info.svg?react';
import CloseSVG from '../svg/close.svg?react';
import PlusSVG from '../svg/plus.svg?react';
import BoldSVG from '../svg/bold.svg?react';
import CodeSVG from '../svg/code.svg?react';
import AlignLeft from '../svg/align-left.svg?react';
import AlignCenter from '../svg/align-center.svg?react';
import AlignRight from '../svg/align-right.svg?react';
import HomeSVG from '../svg/home.svg?react';
import DeleteSVG from '../svg/delete.svg?react';
import EmptySVGURL from '../svg/empty.svg';

export function EmptySVG(props) {
  return <img src={EmptySVGURL} {...props} />;
}

export function PieChartIcon(props) {
  return <Icon component={PieChartSVG} {...props} />;
}

export function InboxIcon(props) {
  return <Icon component={InboxSVG} {...props} />;
}

export function LayoutIcon(props) {
  return <Icon component={LayoutSVG} {...props} />;
}

export function PeopleIcon(props) {
  return <Icon component={PeopleSVG} {...props} />;
}

export function LogIcon(props) {
  return <Icon component={LogSVG} {...props} />;
}

export function SettingIcon(props) {
  return <Icon component={SettingSVG} {...props} />;
}

export function ArrowHeadLeftIcon(props) {
  return <Icon component={ArrowHeadLeftSVG} {...props} />;
}

export function UploadIcon(props) {
  return <Icon component={UploadSVG} {...props} />;
}

export function DownloadIcon(props) {
  return <Icon component={DownloadSVG} {...props} />;
}

export function CodeDownloadIcon(props) {
  return <Icon component={CodeDownloadSVG} {...props} />;
}

export function InfoIcon(props) {
  return <Icon component={InfoSVG} {...props} />;
}

export function CloseIcon(props) {
  return <Icon component={CloseSVG} {...props} />;
}

export function PlusIcon(props) {
  return <Icon component={PlusSVG} {...props} />;
}

export function BoldIcon(props) {
  return <Icon component={BoldSVG} {...props} />;
}

export function CodeIcon(props) {
  return <Icon component={CodeSVG} {...props} />;
}

export function AlignLeftIcon(props) {
  return <Icon component={AlignLeft} {...props} />;
}

export function AlignCenterIcon(props) {
  return <Icon component={AlignCenter} {...props} />;
}

export function AlignRightIcon(props) {
  return <Icon component={AlignRight} {...props} />;
}

export function HomeIcon(props) {
  return <Icon component={HomeSVG} {...props} />;
}
export function DeleteIcon(props) {
  return <Icon component={DeleteSVG} {...props} />;
}
