import React from 'react';

import { useGetOrderDetail } from '@api/orderApi';

import { useCustomSearchParams } from '@hooks/useCustomSearchParams';

import { OrderDetail } from './components/OrderDetail';

export default function PublicOrder() {
  // const intl = useIntl();
  const [search] = useCustomSearchParams();
  const { data, isLoading, isError, isIdle } = useGetOrderDetail({
    params: { code: search.code },
    options: {
      enabled: !!search.code,
    },
  });

  // if (data) {
  //   data?.order?.orderAddress?.phone = `*${data.order.orderAddress.phone.slice(1, -2)}**`;
  // }

  if (!isIdle && !isLoading) {
    return <OrderDetail data={data} isError={isError} />;
  }

  return null;
}
