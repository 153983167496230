import React from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Empty } from 'antd';

import { EmptySVG } from '../../assets/icons';
import { PaymentItem } from './PaymentItem';

export function PaymentList({ banks = [], onClickDelete = () => {}, onClickEdit = () => {}, isShowActions = true }) {
  const intl = useIntl();

  return (
    <>
      {banks.length > 0 ? (
        <Row
          className="m-5 p-5"
          gutter={[
            { xs: 16, sm: 16, lg: 24 },
            { xs: 16, sm: 16, lg: 24 },
          ]}
        >
          {banks.map(bank => (
            <Col span={24} md={12} xxl={8} key={bank.id}>
              <PaymentItem
                item={bank}
                onClickDelete={() => onClickDelete(bank.id)}
                onClickEdit={() => onClickEdit(bank)}
                isShowActions={isShowActions}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Empty
          image={<EmptySVG />}
          imageStyle={{ height: 100 }}
          description={intl.formatMessage({ id: 'views.settings.empty' })}
        />
      )}
    </>
  );
}
