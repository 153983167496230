/* eslint-disable no-unsafe-optional-chaining */
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGINATION } from '@common/constants/theme';

export const useCustomSearchParams = params => {
  const [searchParams, setSearchParams] = useSearchParams(params);

  const transformedSearchParams = useMemo(() => {
    const searchParamsAsObject = Object.fromEntries(new URLSearchParams(searchParams));

    return {
      ...searchParamsAsObject,
      page: +searchParamsAsObject.page || +params?.page || DEFAULT_PAGINATION.PAGE,
      limit: +searchParamsAsObject.limit || +params?.limit || DEFAULT_PAGINATION.LIMIT,
    };
  }, [searchParams, params]);

  return [transformedSearchParams, setSearchParams];
};
