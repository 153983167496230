import { useQuery } from '@tanstack/react-query';

import axiosClient from './axiosClient';

export const locationApi = {
  getProvinces(params) {
    return axiosClient.get('/location/provinces', { params });
  },
  getDistricts(params) {
    return axiosClient.get('/location/districts', { params });
  },
  getDistrictsByProvinceId(provinceId, params) {
    return axiosClient.get(`/location/provinces/${provinceId}/districts`, {
      params,
    });
  },
  getWardByDistrictId(districtId) {
    return axiosClient.get(`/location/districts/${districtId}/wards`);
  },
};

export const useProvinces = ({ params, options } = {}) =>
  useQuery({
    queryKey: ['location', 'provinces', params],
    queryFn: () => locationApi.getProvinces(params),
    select: response => response?.items || [],
    ...options,
  });

export const useDistricts = ({ params, options } = {}) =>
  useQuery({
    queryKey: ['location', 'districts', params],
    queryFn: () => locationApi.getDistricts(params),
    enabled: !!params?.provinceId,
    select: response => response?.items || [],
    ...options,
  });

export const useWards = ({ parentId, options } = {}) =>
  useQuery({
    queryKey: ['location', 'wards', parentId],
    enabled: !!parentId,
    queryFn: () => locationApi.getWardByDistrictId({ parentId }),
    ...options,
  });

export const useDistrictsByProvinceId = ({ provinceId, params, options } = {}) =>
  useQuery({
    queryKey: ['location', 'districts', provinceId, params],
    queryFn: () => locationApi.getDistrictsByProvinceId(provinceId, params),
    enabled: !!provinceId,
    select: response => response?.items || [],
    ...options,
  });

export const useWardsByDistrictId = ({ districtId, options } = {}) =>
  useQuery({
    queryKey: ['location', 'wards', districtId],
    queryFn: () => locationApi.getWardByDistrictId(districtId),
    enabled: !!districtId,
    select: response => response?.items || [],
    ...options,
  });
