import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import { CardWrapper, ContentWrapper } from '@components/layout';
import { OTPModal } from '@components/OTP';
import { Notification } from '@components/Notification';

import {
  useCreateCustomerAddress,
  useGetCustomerByPhone,
  useRequestOtpCode,
  useUpdateCustomerAddress,
  useVerifyOtpCode,
} from '@api/customerProfileApi';

import { ProfileForm } from './components/ProfileForm';
import { SearchPhoneForm } from './components/SearchPhoneForm';
import { STORAGE_KEY } from '../../common/constants/theme';

import { useCountDown } from '@hooks/useCountDown';

function getDefaultAddress(customer) {
  if (_.isEmpty(customer)) {
    return {};
  }
  console.log('customerAddress[0]', _.orderBy(customer.customerAddresses, 'id', 'desc').at(0));
  if (!customer?.defaultAddressId) {
    const sortedList = _.orderBy(customer.customerAddresses, 'id', 'desc');
    return sortedList.at(0);
  }
  return customer?.customerAddresses?.find(item => item?.id === customer?.defaultAddressId) ?? {};
}

function Profile() {
  const intl = useIntl();
  const [isSearch, setIsSearch] = useState(false);
  const requestOtpCode = useRequestOtpCode();
  const verifyOtpCode = useVerifyOtpCode();
  const getCustomerByPhoneMutation = useGetCustomerByPhone();
  const createCustomerAddress = useCreateCustomerAddress();
  const updateCustomerAddress = useUpdateCustomerAddress();
  const [currentCustomer, setCurrentCustomer] = useState(undefined);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchedPhoneData, setSearchedPhoneData] = useState(null);

  const [error, setError] = useState(null);

  const [isStart, setIsStart] = React.useState(false)
  const [isRefresh, setIsRefresh] = React.useState(false)

  const countDown = useCountDown({
    isStart,
    initValue: 1000 * 60 * 3,
    isRefresh
  });

  const onStartCount = () => {
    setIsStart(true);
  };

  useEffect(() => {
    if (!countDown) {
      setIsStart(false)
      if (isRefresh) setIsRefresh(false)
    }
  }, [countDown])

  const getOtpCode = phone => {
    requestOtpCode.mutate(
      { phone },
      {
        onSuccess: () => {
          setIsOpenModal(true);
          if (!countDown) {
            setIsRefresh(true)
            onStartCount()
          } else {
            onStartCount()
          }
        },
        onError: err => {
          setError(err);
          console.log('getOtpCode', err);
          Notification('error', err?.response?.data?.message ?? 'Có lỗi xảy ra.Vui lòng thử lại');
        },
      }
    );
  };

  const handleGetCustomerByPhone = ({ phone }) => {
    if (localStorage.getItem(STORAGE_KEY.OTP_TOKEN)) {
      return getCustomerByPhoneMutation.mutate(phone, {
        onSuccess(response) {
          console.log('handleGetCustomerByPhone', response);
          setIsSearch(true);
          setCurrentCustomer(response?.data ?? null);
        },
        onError(err) {
          console.log('handleGetCustomerByPhone', err);
          if (err.response.status === 401) {
            localStorage.setItem(STORAGE_KEY.OTP_TOKEN, '');
            return getOtpCode(phone);
          }
          Notification('error', intl.formatMessage({ id: 'message.commonError' }));
        },
      });
    }
    getOtpCode(phone);
  };

  const handleSubmitSearchPhone = values => {
    console.log(values);
    setCurrentCustomer(undefined);
    setSearchedPhoneData(values);
    handleGetCustomerByPhone(values);
  };

  const handleSubmitOtp = ({ code }) => {
    const phone = searchedPhoneData?.phone;
    if (!phone) return;
    verifyOtpCode.mutate(
      { phone: searchedPhoneData?.phone, code },
      {
        onSuccess: resp => {
          console.log('handleSubmitOtp', resp);
          setIsOpenModal(false);
          localStorage.setItem(STORAGE_KEY.OTP_TOKEN, resp?.data?.otpToken);
          handleGetCustomerByPhone({ phone });
        },
        onError: err => {
          console.log(err.response);
          Notification('error', intl.formatMessage({ id: 'message.commonError' }));
        },
      }
    );
  };

  const handleSubmitData = values => {
    console.log('handleSubmitData', values);
    const onError = err => {
      Notification('error', err?.message || intl.formatMessage({ id: 'message.commonError' }));
    };
    const onSuccess = () => {
      setIsSearch(false);
      setCurrentCustomer(undefined);
      Notification(
        'success',
        intl.formatMessage({
          id: `message.${values.id ? 'updateSuccess' : 'createSuccess'}`,
        })
      );
    };
    const data = { ...values, phone: currentCustomer?.phone ?? searchedPhoneData?.phone, isDefault: true };
    if (values.id) {
      updateCustomerAddress.mutate(data, { onError, onSuccess });
    } else {
      createCustomerAddress.mutate(data, { onError, onSuccess });
    }
  };

  return (
    <>
      {!isSearch && (
        <CardWrapper
          style={{
            maxWidth: 900,
            margin: '0 auto',
            width: '100%',
            backgroundColor: 'var(--white)',
          }}
        >
          <ContentWrapper
            title={intl.formatMessage({
              id: 'views.profile.numberPhoneLookupTitle',
            })}
            hasBorder
          >
            <SearchPhoneForm
              count={countDown}
              error={error}
              isShowSearchBtn={!getCustomerByPhoneMutation.isLoading && currentCustomer !== undefined}
              onSubmit={handleSubmitSearchPhone}
            />
          </ContentWrapper>
        </CardWrapper>
      )}

      {!getCustomerByPhoneMutation.isLoading && currentCustomer !== undefined && (
        <CardWrapper
          style={{
            maxWidth: 900,
            margin: '0 auto',
            width: '100%',
            backgroundColor: 'var(--white)',
            marginTop: 32,
          }}
        >
          <ProfileForm
            initialValues={{ ...getDefaultAddress(currentCustomer), fullName: currentCustomer?.fullName ?? '' }}
            onSubmit={handleSubmitData}
            isAddNew={_.isEmpty(currentCustomer)}
          />
        </CardWrapper>
      )}

      <OTPModal
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onSubmit={handleSubmitOtp}
        error={!verifyOtpCode.isLoading && verifyOtpCode.error}
      />
    </>
  );
}

export default Profile;
