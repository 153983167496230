import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LogoutOutlined } from '@ant-design/icons';

import { StyledSider, StyledSiderUserInfo, StyledBreakLine, StyledButton } from '@styles/overrides';

import { UserInfo } from '@components/user/UserInfo';

import { setBreadcrumbs } from '@store/slices/layoutSlice';
import { getCurrentUser, doLogout } from '@store/slices/authSlice';

import { SideMenu } from './SideMenu';
import {
  findMatchedMenuItemByPathname,
  findRelatedMatchedMenuItemByPathname,
  findRelatedMenuItemsByMatchedMenuItem,
} from '../utils/handleMenuItems';

const normalizeMenuItem = item => {
  item.text = item.label;

  if (item.url) {
    item.label = <Link to={item.url}>{item.label}</Link>;
  }

  return item;
};

const normalizeMenuItems = (list = []) =>
  list?.map(item => {
    const newItem = normalizeMenuItem(item);

    return newItem;
  });

export function SideBar({ collapsed = false, items = [] }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onClick = e => {
    console.log('click ', e);
  };

  const menuItems = useMemo(() =>
    normalizeMenuItems(
      items.map(item => ({
        ...item,
        children: item?.children?.length
          ? normalizeMenuItems(
              item.children.map(child => ({
                ...child,
                label: intl.formatMessage({ id: `layout.sider.${child.key}` }),
              }))
            )
          : null,
        label: intl.formatMessage({ id: `layout.sider.${item.key}` }),
      }))
    )
  );

  useEffect(() => {
    let matchedMenuItem = findMatchedMenuItemByPathname(menuItems, location.pathname);

    console.log(location.pathname, menuItems);

    if (!matchedMenuItem) {
      matchedMenuItem = findRelatedMatchedMenuItemByPathname(menuItems, location.pathname);
    }

    if (!matchedMenuItem) {
      return;
    }

    const matchedMenuItems = findRelatedMenuItemsByMatchedMenuItem(menuItems, matchedMenuItem);

    console.log('matchedMenuItems', matchedMenuItems);

    const layoutData = matchedMenuItems?.reduce(
      (obj, curr) => {
        obj.selectedKeys.push(curr.key);
        obj.breadcrumbs.push({
          key: curr.key,
          label: curr.text ?? curr.label,
          url: curr.url,
        });

        return obj;
      },
      { selectedKeys: [], breadcrumbs: [] }
    );

    dispatch(setBreadcrumbs(layoutData.breadcrumbs));

    setSelectedKeys(layoutData.selectedKeys);
  }, [location.pathname]);
  return (
    <StyledSider collapsible collapsed={collapsed} trigger={null} style={{ position: 'fixed' }}>
      <StyledBreakLine />
      <StyledSiderUserInfo>
        <UserInfo
          isVertical
          fullName={currentUser?.fullName}
          role={currentUser?.role}
          isCollapsed={collapsed}
          email={currentUser?.email}
        />
      </StyledSiderUserInfo>
      <StyledBreakLine />
      <SideMenu
        triggerSubMenuAction="click"
        collapsed="true"
        items={menuItems}
        selectedKeys={selectedKeys}
        onClick={onClick}
        mode="inline"
      />
      <StyledBreakLine />
      <StyledButton
        type="text"
        style={{ fontSize: 16, fontWeight: 400, color: 'var(--secondary600)' }}
        onClick={() => dispatch(doLogout())}
        block
      >
        {collapsed ? (
          <LogoutOutlined />
        ) : (
          <div className="flex justify-center items-center hover:text-white py-4">
            <LogoutOutlined className="mr-2 rotate-180" />
            {intl.formatMessage({ id: 'common.btn.logout' })}
          </div>
        )}
      </StyledButton>
    </StyledSider>
  );
}
