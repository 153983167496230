import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { httpService } from './api/axiosClient';
import { store } from './store';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import './assets/style/index.css';
import './assets/style/override.css';

httpService.setupInterceptors(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>,
);
