import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Descriptions } from 'antd';
import { formatTime, formatAddress } from '@common/utils';

const StyledOrderInfo = styled.div`
  .ant-descriptions-item-container {
    display: flex;
  }

  .ant-descriptions-item-label {
    font-size: 16px;
    color: var(--gray500);
    // min-width: 180px;
    line-clamp: 1;
  }

  .ant-descriptions-item-content {
    font-size: 16px;
    color: var(--gray900);
  }

  @media (max-width: 450px) {
    .ant-descriptions-item-container {
      flex-direction: column;
    }
  }
`;

export function OrderInfo({ data, isPublic = false, }) {
  const intl = useIntl();

  return (
    <StyledOrderInfo>
      <Descriptions column={1}>
        <Descriptions.Item
          label={intl.formatMessage({ id: 'common.field.livestreamDate' })}
        // span={2}
        >
          {formatTime(data?.livestreamDate)}
        </Descriptions.Item>
        <Descriptions.Item label={intl.formatMessage({ id: 'views.orders.orderInfo.fullName' })}>
          {data?.orderAddress?.fullName ?? ''}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'views.orders.orderInfo.phoneNumber',
          })}
        >
          {data?.orderAddress?.phone ?? ''}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'views.orders.orderInfo.address',
          })}
        // span={2}
        >
          {formatAddress(data?.orderAddress)}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'views.orders.orderInfo.confirmPayment',
          })}
        >
          {data?.paymentStatus ? intl.formatMessage({
            id: `common.status.paymentStatus.${data?.paymentStatus}`,
          }) : null}
        </Descriptions.Item>
        {data?.shop?.name && (
          <Descriptions.Item
            label={intl.formatMessage({
              id: 'common.field.shopId',
            })}
          >
            {data?.shop.name}
          </Descriptions.Item>
        )}
        {data?.noteAdmin && !isPublic && (
          <Descriptions.Item
            label={intl.formatMessage({
              id: 'views.orders.orderInfo.noteAdmin',
            })}
          >
            {data?.noteAdmin}
          </Descriptions.Item>
        )}
      </Descriptions>
    </StyledOrderInfo>
  );
}
