import React from 'react';
import { useIntl } from 'react-intl';

import { StyledCollapse, StyledCollapsePanel, StyledTypographyParagraph } from '@styles/overrides';

import { CardWrapper, ContentWrapper } from '@components/layout';
import { CollapseHeader } from '@components/collapse';

// import { OrderHeading } from '@views/orders/components/OrderHeading';

import { OrderInfo } from '@views/order-detail/components/OrderInfo';
import { OrderInfoDetail } from '@views/order-detail/components/OrderInfoDetail';

export function OrderDetail({ data, isError }) {
  const intl = useIntl();

  if (isError) {
    return (
      <StyledTypographyParagraph style={{ textAlign: 'center', marginTop: 32 }}>
        {intl.formatMessage({ id: 'views.homepage.orderNotExist' })}
      </StyledTypographyParagraph>
    );
  }

  console.log('OrderDetail', data);

  return (
    <CardWrapper style={{ backgroundColor: 'var(--white)' }}>
      {/* <OrderHeading orderId={data?.code} status={data?.status} /> */}

      <ContentWrapper>
        <StyledCollapse defaultActiveKey={[1, 2]} expandIconPosition="end">
          <StyledCollapsePanel
            header={
              <CollapseHeader
                title={intl.formatMessage({
                  id: 'views.products.title.orderInfo',
                })}
              />
            }
            key={1}
          >
            <OrderInfo
              data={data}
              livestreamDate={data?.livestreamDate ?? ''}
              orderAddress={data?.orderAddress ?? {}}
              paymentStatus={data?.payment_status ?? ''}
              noteAdmin={data?.noteAdmin ?? {}}
              isPublic
              shop={data?.shop ?? {}}
            />
          </StyledCollapsePanel>
          <StyledCollapsePanel
            header={
              <CollapseHeader
                title={intl.formatMessage({
                  id: 'views.products.title.orderDetails',
                })}
              />
            }
            key={2}
          >
            <OrderInfoDetail
              data={data}
              products={data?.items}
              totalPrice={data?.subTotal}
              currency={data?.currency}
              note={data?.note}
              code={data?.code}
              banks={data?.bank?.items}
              noteSetting={data.info?.items?.[0]?.value}
            />
          </StyledCollapsePanel>
        </StyledCollapse>
      </ContentWrapper>
    </CardWrapper>
  );
}
