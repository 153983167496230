import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Grid } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { ArrowHeadLeftIcon } from '@assets/icons';

import {
  StyledSpace,
  StyledLayoutHeader,
  StyledTriggerBtn,
  StyledButton,
} from '@styles/overrides';

import { HeaderBreadcrumb } from '@components/breadcrumb/HeaderBreadcrumb';
import { UserRole, UserInfo } from '@components/user';

import { getBreadcrumbs } from '@store/slices/layoutSlice';
import { doLogout, getCurrentUser } from '@store/slices/authSlice';

export function Header({ onCollapse }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const currentUser = useSelector(getCurrentUser);
  const breadcrumbs = useSelector(getBreadcrumbs);

  return (
    <StyledLayoutHeader>
      <StyledSpace
        style={{
          justifyContent: 'space-between',
          width: '100%',
          paddingRight: 0,
        }}
      >
        <StyledSpace>
          <StyledTriggerBtn onClick={onCollapse}>
            <ArrowHeadLeftIcon />
          </StyledTriggerBtn>

          <HeaderBreadcrumb items={breadcrumbs} />
        </StyledSpace>

        <StyledSpace size={0} className="right-block">
          <StyledButton
            type="text"
            style={{ fontSize: 16, fontWeight: 400, color: 'var(--dark)' }}
            onClick={() => dispatch(doLogout())}
            className="!shadow-none mt-1"
          >
            {screens.xs ? (
              <LogoutOutlined />
            ) : (
              intl.formatMessage({ id: 'common.btn.logout' })
            )}
          </StyledButton>

          {/* <div className="fill-[gray] gba(99, 115, 129, 1)] w-5 h-5 cursor-pointer relative">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
            </svg>

            <div className="bg-red-500 text-white font-semibold text-[14px] items-center flex rounded-[999px] absolute py-[2px] px-[6px] -top-2 left-2">
              <div className="mx-auto">8</div>
            </div>
          </div> */}

          {!screens.xs && (
            <>
              <UserRole role={currentUser?.role} />
              <UserInfo fullName={currentUser?.fullName} />
            </>
          )}
        </StyledSpace>
      </StyledSpace>
    </StyledLayoutHeader>
  );
}
