import React from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';

import { StyledTypographyTitle } from '@styles/overrides';

const StyledContentWrapper = styled.div`
  background-color: white;
  border-radius: 12px;
  margin: 0 0 10px 0;

  .titleWrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
    }

    .ant-typography {
      color: var(--gray900);
      margin-bottom: 0;
    }

    ${props => {
      if (props.hasBorder) {
        return css`
          padding-bottom: 5px;
          border-bottom: 3px solid var(--gray200);
        `;
      }
    }}
  }

  ${props => {
    if (props.size === 'small') {
      return css`
        .titleWrapper .ant-typography {
          font-size: 14px;
        }
      `;
    }
  }}
`;

export function ContentWrapper({ title, hasBorder = false, children, extraActions, className, ...restProps }) {
  return <StyledContentWrapper className={clsx('px-[26px] py-[18px]', className)} hasBorder={hasBorder} {...restProps}>
    {title && (
      <div className="titleWrapper ">
        <StyledTypographyTitle level={5}>{title}</StyledTypographyTitle>

        {extraActions}
      </div>
    )}

    {children}
  </StyledContentWrapper>;
}
