import React, { useState, useMemo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Grid, Drawer } from 'antd';
import { PeopleIcon, PieChartIcon } from '@assets/icons';
import { HistoryOutlined, SettingFilled, ShopFilled, ShoppingFilled, TagFilled } from '@ant-design/icons';
import { ROLE } from '@common/constants/roles';
import { PATH_NAME } from '@common/constants/routes';

import { StyledLayout, StyledLayoutContentWrapper, StyledLayoutContent } from '@styles/overrides';

import { NotFound } from '@components/layout';

import { getCurrentUser } from '@store/slices/authSlice';

import { Footer } from './components/Footer';
import { SideBar } from './components/SideBar';
import { Header } from './components/Header';
import { filteredMenuItemsByCurrentRole } from './utils/handleMenuItems';

const MENU_ITEMS = [
  {
    url: PATH_NAME.DASHBOARD,
    key: 'dashboard',
    icon: <PieChartIcon />,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    url: PATH_NAME.ORDERS,
    key: 'orders',
    icon: <ShoppingFilled />,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.VIEWER, ROLE.SALES, ROLE.STAFF],
  },
  {
    key: 'product_manage',
    icon: <TagFilled />,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.SALES, ROLE.STAFF],
    children: [
      {
        url: PATH_NAME.PRODUCTS,
        key: 'products',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
      },
      {
        url: PATH_NAME.CATEGORIES,
        key: 'manage_category',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
      },
      {
        url: PATH_NAME.ATTRIBUTES,
        key: 'manage_attribute',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
      },
    ],
  },
  {
    key: 'user_manage',
    icon: <PeopleIcon />,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
    children: [
      {
        url: PATH_NAME.USERS,
        key: 'users',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
      },
      {
        url: PATH_NAME.CUSTOMER_MANAGE,
        key: 'customers',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
      },
    ],
  },
  {
    url: PATH_NAME.CUSTOMER_MANAGE,
    key: 'customers',
    icon: <PeopleIcon />,
    roles: [ROLE.VIEWER, ROLE.SALES, ROLE.STAFF],
  },
  { url: PATH_NAME.STORES, key: 'shops', icon: <ShopFilled />, roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN] },
  {
    key: 'logs',
    icon: <HistoryOutlined />,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
    children: [
      {
        url: PATH_NAME.ORDER_LOGS,
        key: 'order_logs',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.VIEWER, ROLE.STAFF],
      },
      {
        url: PATH_NAME.LOGS,
        key: 'activity_logs',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.VIEWER, ROLE.STAFF],
      },
    ],
  },
  {
    key: 'settings',
    icon: <SettingFilled />,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
    children: [
      {
        url: PATH_NAME.SETTINGS_COMMON,
        key: 'common_setting',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.VIEWER, ROLE.STAFF],
      },
      {
        url: PATH_NAME.SETTINGS_WEBSITE,
        key: 'website_setting',
        roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.VIEWER, ROLE.STAFF],
      },
    ],
  },
];

export default function DashboardLayout() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const screens = Grid.useBreakpoint();

  const filteredMenuItems = useMemo(() => filteredMenuItemsByCurrentRole(MENU_ITEMS, currentUser?.role), [currentUser]);

  const handleToggleCollapse = () => {
    setIsCollapsed(prev => !prev);
  };

  const isAdmin = [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.VIEWER, ROLE.STAFF].includes(currentUser?.role);

  return (
    <>
      <StyledLayout>
        {screens.lg && <SideBar collapsed={isCollapsed} items={filteredMenuItems} />}

        <StyledLayoutContentWrapper collapsed={isCollapsed.toString()}>
          <Header onCollapse={handleToggleCollapse} />
          {isAdmin ? (
            <StyledLayoutContent>
              <Suspense fallback={null}>
                <Outlet />
              </Suspense>
            </StyledLayoutContent>
          ) : (
            <NotFound />
          )}
          <Footer />
        </StyledLayoutContentWrapper>
      </StyledLayout>

      {!screens.lg && isAdmin && (
        <Drawer
          open={isCollapsed}
          closable={false}
          onClose={() => setIsCollapsed(false)}
          styles={{
            wrapper: { width: 'auto' },
          }}
          placement="left"
        >
          <SideBar items={filteredMenuItems} />
        </Drawer>
      )}
    </>
  );
}
