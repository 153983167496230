import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from '@store/slices/authSlice';

export function Permission({ children, noAccess, roles = [] }) {
  const user = useSelector(getCurrentUser);
  // console.log(user);

  const deny = useMemo(() => !roles?.includes(user?.role), [roles, user?.role, noAccess]);

  return deny ? null || noAccess : children;
}
