import moment from "moment"

export const getTimeString = (time = 0) => {
  return `${moment.duration(time).minutes() < 10
    ? "0" + moment.duration(time).minutes()
    : moment.duration(time).minutes()
    }:${moment.duration(time).seconds() < 10
      ? "0" + moment.duration(time).seconds()
      : moment.duration(time).seconds()
    }`;
};

export const getTimeMinutes = (time = 0) => {
  return `${moment.duration(time).minutes() < 10
    ? "0" + moment.duration(time).minutes()
    : moment.duration(time).minutes()
    }`
}