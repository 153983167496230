import { useQuery, useMutation } from '@tanstack/react-query';

import axiosClient from './axiosClient';

export const generalSettingApi = {
  getNote(params) {
    return axiosClient.get('/general-settings/note', { params });
  },
  updateNote(data) {
    return axiosClient.put('/general-settings/note', data);
  },
};

export const useGeneralSettingNote = ({ params, options } = {}) =>
  useQuery({
    queryKey: ['general-settings/note', params],
    queryFn: () => generalSettingApi.getNote(params),
    ...options,
  });

export const useUpdateGeneralSettingNote = () => {
  return useMutation({
    mutationFn: (data) => generalSettingApi.updateNote(data)
  });
}
