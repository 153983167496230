import React from 'react';

import { StyledSelect } from '@styles/overrides';

import { useProvinces } from '@api/locationApi';

export function ProvinceSelect({ name, label, defaultValue, ...props }) {
  const { data, isLoading, refetch } = useProvinces();

  return (
    <StyledSelect
      {...props}
      onFocus={refetch}
      name={name}
      loading={isLoading}
      options={data?.map(item => ({ label: item.name, value: item.id })) ?? []}
    />
  );
}
