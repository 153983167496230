import React from 'react';
import { useIntl } from 'react-intl';

import { StyledTypographyParagraph } from '@styles/overrides';

import { ProductTable } from '@components/product/ProductTable';

import { OrderNoteSetting } from './OrderNoteSetting';
import { OrderBankSetting } from './OrderBankSetting';

export function OrderInfoDetail({
  data,
  banks,
  noteSetting,
  isShowActions,
  onUpdateOrderItemNameSuccess = () => {},
}) {
  const intl = useIntl();

  return (
    <>
      <ProductTable
        dataSource={data?.items}
        totalPrice={data?.subTotal}
        currency={data?.currency}
        onUpdateOrderItemNameSuccess={onUpdateOrderItemNameSuccess}
        isShowActions={isShowActions}
      />

      <StyledTypographyParagraph style={{ marginTop: 16 }}>
        <p style={{ color: 'red' }}>
          {intl.formatMessage({ id: 'views.orders.orderInfo.noteDescription' })}: {data?.code}
        </p>
        <p style={{ color: 'var(--gray900)', marginTop: 16 }}>
          {intl.formatMessage({ id: 'views.orders.orderInfo.note' })}: {data?.code}
        </p>
      </StyledTypographyParagraph>

      <OrderNoteSetting noteSetting={noteSetting} shopId={data?.shopId} />

      <OrderBankSetting banks={banks} shopId={data?.shopId} />
    </>
  );
}
