import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';

import THEME from './config/themes';
import AppLocale from './config/translations';
import { validateMessages } from './common/constants/theme';
import GlobalStyle from './styles/globalStyle';
import Routes from './Routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = useSelector(state => state.app.currentLocale);

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale], requiredMark: false }}
    >
      <IntlProvider locale={locale} messages={flatten(AppLocale[locale].messages)}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />
            <Routes />
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
