import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Form, Row, Col } from 'antd';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import { StyledForm, StyledSpace, StyledButton } from '@styles/overrides';

const StyledFilterForm = styled.div`
  padding: 16px 24px;
  background-color: var(--gray100);
  border: 1px solid var(--greyscale300);
  box-shadow: 0px 2px 0px 0px rgba(231, 235, 238, 0.2);
  border-radius: 8px;
  margin-bottom: 16px;
  .ant-btn-lg {
    height: 32px !important;
    padding: 0 15px !important;
  }
`;

export function FilterForm({ children, initialValues, extraActions, disableButtons, isShowResetBtn = true, ...props }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleReset = () => {
    form.resetFields();
    form.submit();
  };

  return (
    <StyledFilterForm>
      <Row gutter={[30, 20]}>
        <Col xs={32} sm={12} xl={18}>
          <StyledForm {...props} form={form}>
            {children}

          </StyledForm>
        </Col>
        {!disableButtons ? (
          <Col xs={32} sm={12} xl={6}>
            <StyledSpace size={16} style={{ justifyContent: 'flex-end' }}>
              <StyledButton type="dark" onClick={form.submit} icon={<SearchOutlined />}>
                {intl.formatMessage({ id: 'common.btn.search' })}
              </StyledButton>

              {isShowResetBtn && (
              <StyledButton onClick={handleReset} icon={<ReloadOutlined />}>
                {intl.formatMessage({ id: 'common.btn.reset' })}
              </StyledButton>
     )}

              {extraActions}
            </StyledSpace>
          </Col>
        ) : null}

      </Row>
    </StyledFilterForm>
  );
}
