import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { VNMobilePhonePattern } from '@common/utils/validationUtils';

import { StyledButton, StyledForm, StyledFormItem, StyledInput } from '@styles/overrides';
import { getTimeString } from 'src/layout/utils/getTimeString';

export function SearchPhoneForm({ count, error, isShowSearchBtn, onSubmit = () => { } }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  // console.log('count', count);

  return (
    <StyledForm layout="vertical" size="large" onFinish={onSubmit} form={form}>
      <StyledFormItem
        name="phone"
        label={intl.formatMessage({
          id: 'views.profile.numberPhoneCode',
        })}
        rules={[
          { required: true },
          {
            pattern: VNMobilePhonePattern,
            message: intl.formatMessage({
              id: 'validate.invalidPhoneNumber',
            }),
          },
        ]}
      >
        <StyledInput
          disabled={isShowSearchBtn}
          placeholder={intl.formatMessage({
            id: 'views.orders.form.placeholder.phone',
          })}
        />
      </StyledFormItem>

      {!isShowSearchBtn && count === 180000 || count === 0 ? (
        <StyledFormItem style={{ textAlign: 'center' }}>
          <StyledButton type="primary" htmlType="submit">
            {intl.formatMessage({ id: 'common.btn.search' })}
          </StyledButton>
        </StyledFormItem>
      ) : (
        <StyledFormItem style={{ textAlign: 'center' }}>
          <StyledButton type="primary" htmlType="submit" disabled>
            {getTimeString(count)}
          </StyledButton>
        </StyledFormItem>
      )}

      {error && <span color="red">{error?.response?.message}</span>}
    </StyledForm>
  );
}
