import { useQuery, useMutation } from '@tanstack/react-query';

import axiosClient from './axiosClient';

export const bankSettingApi = {
  getAll(params) {
    return axiosClient.get('/bank-settings', { params });
  },
  create(data) {
    return axiosClient.post('/bank-settings', data);
  },
  update({ id, ...data }) {
    return axiosClient.patch(`/bank-settings/${id}`, data);
  },
  delete(id) {
    return axiosClient.delete(`/bank-settings/${id}`);
  },
};

export const useBankSettings = ({ params, options } = {}) =>
  useQuery({
    queryKey: ['bank-settings', params],
    queryFn: () => bankSettingApi.getAll(params),
    select: response => response?.items ?? [],
    ...options,
  });

export const useCreateBankSetting = ({ shopId }) =>
  useMutation({ mutationFn: data => bankSettingApi.create({ ...data, shopId }) });

export const useUpdateBankSetting = () =>
  useMutation({
    mutationFn: bankSettingApi.update,
  });

export const useDeleteBankSetting = () =>
  useMutation({
    mutationFn: bankSettingApi.delete,
  });
