import React from 'react';
import { Grid } from 'antd';

import { StyledTable } from '@styles/overrides';

export function Table({ ...props }) {
  const screens = Grid.useBreakpoint();

  return (
    <div>
      <StyledTable bordered scroll={{ x: screens.xl ? undefined : 'max-content' }} {...props} />
    </div>
  );
}
