import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { STORAGE_KEY } from '@common/constants/theme';
import { PATH_NAME } from '@common/constants/routes';

import { Loading } from '@components/loading';

import { doCheckAuth } from '@store/slices/authSlice';

function withAuthenticate(ComposedComponent) {
  const Authenticate = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn } = useSelector(state => state.auth);
    const [isCheckingStatus, setIsCheckingStatus] = useState(false);

    const bootstrapAsync = async () => {
      setIsCheckingStatus(true);
      if (location.pathname === PATH_NAME.CUSTOMER_ORDER) {
        console.log('bootstrapAsync', location.pathname);
        return;
      }
      try {
        const accessToken = localStorage.getItem(STORAGE_KEY.TOKEN);
        if (accessToken) {
          const result = await dispatch(doCheckAuth({ accessToken }));
          console.log('bootstrapAsync', result);
          if (!result.payload?.id || !['supper_admin', 'admin'].includes(result.payload.role)) {
            navigate(PATH_NAME.LOGIN);
          }
        } else {
          navigate(PATH_NAME.LOGIN);
        }
      } catch (err) {
        console.info(err);
        navigate(PATH_NAME.LOGIN);
      } finally {
        setIsCheckingStatus(false);
      }
    };

    useEffect(() => {
      if (!isLoggedIn && location.pathname !== PATH_NAME.CUSTOMER_ORDER) {
        bootstrapAsync();
      } else {
        setIsCheckingStatus(false);
      }
    }, []);

    if (isCheckingStatus) {
      return <Loading />;
    }

    return <ComposedComponent {...props} />;
  };
  Authenticate.displayName = 'Authenticate';
  return Authenticate;
}

export default withAuthenticate;
