import React from 'react';
import _ from 'lodash';

import { PaymentList } from '@components/payment';

import { useBankSettings } from '@api/bankSettingApi';

export function OrderBankSetting({ banks, shopId }) {
  const { data = [] } = useBankSettings({
    params: { shopId },
    options: {
      enabled: _.isNil(banks),
    },
  });

  const items = (banks ?? data)?.filter(item => item.isActive === true) ?? [];

  return <PaymentList banks={items} isShowActions={false} />;
}
