import { STORAGE_KEY } from '@common/constants/theme';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

const axiosClient = axios.create({
  baseURL: process.env.BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  paramsSerializer: params =>
    queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    }),
});

axiosClient.interceptors.request.use(
  config => {
    config.url;
    const token = localStorage.getItem(STORAGE_KEY.OTP_TOKEN);

    if (token && config.url.indexOf('/auth') === -1) {
      config.headers['otp-token'] = token;
    }
    return config;
  },
  error => Promise.reject(error)
);

export const useRequestOtpCode = () =>
  useMutation({
    mutationFn: data => axiosClient.post('/auth/otp-request', data).then(res => res.data),
  });

export const useVerifyOtpCode = () =>
  useMutation({
    mutationFn: data => axiosClient.post('/auth/verify-otp-code', data).then(res => res.data),
  });

export const useGetCustomerByPhone = () =>
  useMutation({
    mutationFn: phone => axiosClient.get(`/customers/phone/${phone}`).then(res => res.data),
  });

export const useUpdateCustomerAddress = () =>
  useMutation({
    mutationFn: data => axiosClient.put(`/customers/phone/${data.phone}/updateAddress`, data),
  });

export const useCreateCustomerAddress = () =>
  useMutation({
    mutationFn: data => axiosClient.put(`/customers/phone/${data.phone}/create`, data),
  });
