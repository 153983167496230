export const PATH_NAME = {
  ROOT: '/',
  ADMINS: '/admins',
  CUSTOMER_ORDER: '/orders',
  LOGIN: '/auth/login',
  DASHBOARD: '/dashboard',
  DASHBOARD_DETAIL: '/dashboard/detail',
  ORDERS: '/manage/orders',
  ORDER_DETAIL: '/manage/orders/:id',
  PRODUCTS: '/manage/products',
  LIVESTREAM_PRODUCTS: '/livestream-products',
  CATEGORIES: '/manage/categories',
  PRODUCT_MANAGE: '/products/manage',
  PRODUCT_CREATE: '/products/create',
  PRODUCT_DETAIL: '/manage/products/:id',
  CUSTOMERS: '/manage/customers',
  USERS: '/manage/users',
  ADMIN_MANAGE: '/users/manage/admins',
  CUSTOMER_MANAGE: '/manage/customers',
  LOGS: '/logs',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  USER: '/user',
  SHOPS: '/shops',
  MANAGE_WEBSITE: '/web',
  MANAGE_LIVESTREAM: '/',
  INDEX: '/',
  ORDER_CREATE: '/manage/orders/create',
  MANAGE_PRODUCT: '/manage-product',
  STORES: '/manage/stores',
  ATTRIBUTES: '/manage/attributes',
  SETTINGS_COMMON: '/settings-common',
  SETTINGS_WEBSITE: '/settings-website',
  SETTINGS_WEBSITE_BANNER_CREATE: '/settings-website/create',
  SETTINGS_WEBSITE_BANNER_UPDATE: '/settings-website/update',
  ORDER_LOGS: '/order-logs',
};

export const PATH_NAME_ACTION = {
  EDIT: 'edit',
};
