import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';

import { StyledForm, StyledFormItem, StyledInput } from '@styles/overrides';

import { CardWrapper } from '@components/layout';

export const OrderItemForm = ({ initialValues, ...props }) => {
  const intl = useIntl();

  useEffect(() => {
    props.form?.setFieldsValue(initialValues);
  }, [initialValues, props.form]);

  return (
    <CardWrapper>
      <StyledForm layout="vertical" size="large" {...props}>
        <Row gutter={[32, 0]}>
          <Col span={24}>
            <StyledFormItem
              label={intl.formatMessage({
                id: 'components.orderItem.form.label.productName',
              })}
              name="productName"
              rules={[{ required: true }]}
            >
              <StyledInput
                placeholder={intl.formatMessage({
                  id: 'components.orderItem.form.placeholder.productName',
                })}
              />
            </StyledFormItem>
          </Col>
        </Row>
      </StyledForm>
    </CardWrapper>
  );
};
