import { combineReducers } from '@reduxjs/toolkit';

import appReducer from './slices/appSlice';
import authReducer from './slices/authSlice';
import layoutReducer from './slices/layoutSlice';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  layout: layoutReducer,
});

export default rootReducer;
