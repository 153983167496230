import React from 'react';

import { StyledSelect } from '@styles/overrides';

import { useWardsByDistrictId } from '@api/locationApi';

export function WardSelect({ name, label, defaultValue, districtId, ...props }) {
  const { data, isLoading } = useWardsByDistrictId({ districtId });

  return (
    <StyledSelect
      {...props}
      name={name}
      loading={isLoading}
      options={data?.map(item => ({ label: item.name, value: item.id })) ?? []}
    />
  );
}
