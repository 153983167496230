import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Col, Row } from 'antd';
import { formatCurrency } from '@common/utils/formatUtils';

import { StyledButton, StyledSpace, StyledTypographyText } from '@styles/overrides';

import { Table } from '@components/table';

import { OrderItemModal } from '../../views/orders/components/OrderItem';

export function ProductTable({
  dataSource = [],
  totalPrice,
  // currency,
  isFixedWidth = false,
  isShowActions = false,
  onUpdateOrderItemNameSuccess = () => {},
}) {
  const intl = useIntl();
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => index + 1,
      width: isFixedWidth ? 60 : '',
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'productCode',
      width: isFixedWidth ? 80 : undefined,
    },
    {
      title: intl.formatMessage({ id: 'components.product.description' }),
      width: isFixedWidth ? 200 : undefined,
      render: ({ productName, product }) => {
        if (productName) return productName;

        return product?.name ?? '';
      },
    },
    {
      title: intl.formatMessage({ id: 'components.product.quantity' }),
      dataIndex: 'quantity',
      width: isFixedWidth ? 60 : undefined,
    },
    {
      title: intl.formatMessage({ id: 'components.product.price' }),
      dataIndex: 'price',
      render: value => formatCurrency(value),
    },
    {
      title: intl.formatMessage({ id: 'components.product.total' }),
      dataIndex: 'subTotal',
      render: value => formatCurrency(value),
    },
    {
      title: 'Thao tác',
      hidden: !isShowActions,
      render: record => (
        <StyledButton onClick={() => setSelectedRow(record)}>
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>
      ),
    },
  ];

  const renderFooter = () => (
    <Row>
      <Col span={24} xl={{ span: 10, offset: 14 }}>
        <StyledSpace className="font-bold" style={{ justifyContent: 'space-between' }}>
          <StyledTypographyText className="text-[18px] text-[--greyscale700] ">
            {intl.formatMessage({ id: 'components.product.totalPrice' })}:
          </StyledTypographyText>
          <StyledTypographyText className="text-[20px] text-[--greyscale700] ml-0 sm:ml-12">
            {formatCurrency(totalPrice)} đ
          </StyledTypographyText>
        </StyledSpace>
      </Col>
    </Row>
  );

  return (
    <>
      <Table
        rowKey="productId"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        footer={() => renderFooter()}
      />

      {isShowActions && (
        <OrderItemModal
          open={!_.isNil(selectedRow)}
          onCancel={() => {
            setSelectedRow(null);
          }}
          initialValues={selectedRow}
          onSuccess={() => {
            setSelectedRow(null);
            onUpdateOrderItemNameSuccess();
          }}
        />
      )}
    </>
  );
}
