import React from 'react';
import styled from 'styled-components';

const StyledCardWrapper = styled.div`
  padding: 16px;
  box-shadow: 0 6px 18px 0 var(--shadowz8);
  border-radius: 24px;
`;

export const CardWrapper = ({ children, ...props }) => <StyledCardWrapper {...props}>{children}</StyledCardWrapper>;
