import { useMutation } from '@tanstack/react-query';

import axiosClient from './axiosClient';

export const orderItemApi = {
  updateName({ id, ...data }) {
    return axiosClient.patch(`/order-items/${id}/update-name`, data);
  },
};
export const useUpdateOrderItemName = () => useMutation(orderItemApi.updateName);
