import _ from 'lodash';

export const findMatchedMenuItemByPathname = (items, pathname) => {
  const result = _(items)
    .thru(coll => _.union(coll, _.map(coll, 'children') || []))
    .flatten()
    .find({ url: pathname });

  return result;
};

export const findRelatedMatchedMenuItemByPathname = (items, pathname) =>
  items.find(item => pathname.includes(item.url));

export const findRelatedMenuItemsByMatchedMenuItem = (items = [], matchedItem, menuItems = [], isChildren = false) => {
  for (const f of items) {
    const _menuItems = isChildren ? menuItems : [];
    _menuItems.push(f);
    if (f.children?.length) {
      findRelatedMenuItemsByMatchedMenuItem(f.children, matchedItem, _menuItems, true);
    }
    if (f.key === matchedItem.key) {
      console.log('findItems', _menuItems);
      return _menuItems;
    }
  }
  return [matchedItem];
};

export const filteredMenuItemsByCurrentRole = (menuItems = [], currentRole) =>
  menuItems.filter(item => item?.roles?.includes(currentRole));
