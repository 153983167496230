import React from 'react';
import styled from 'styled-components';

import { StyledLayoutFooter } from '@styles/overrides';

const StyledFooterTop = styled.div`
  color: var(--black);

  @media (max-width: 575px) {
    font-size: 12px;
  }
`;

export function Footer(props) {
  return <StyledLayoutFooter {...props}>
    <StyledFooterTop>
      <strong>CÔNG TY TNHH THƯƠNG MẠI DỊCH VỤ TRANG NAM DŨNG</strong>
      <p>
        Số ĐKKD: 3603794118 Ngày cấp: 09-03-2021 Nơi cấp: Sở Kế Hoạch và Đầu
        Tư Tỉnh Đồng Nai.
      </p>
      <p>
        Địa chỉ: Số 334/13/7, đường Võ Thị Sáu, KP 1 - Phường Thống Nhất -
        Thành phố Biên Hoà - Đồng Nai.
      </p>
    </StyledFooterTop>
    <p>Powered by PSA Solutions</p>
  </StyledLayoutFooter>;
}
