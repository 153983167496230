import React, { lazy } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PATH_NAME } from '@common/constants/routes';
import { ROLE } from '@common/constants/roles';

import { NotFound } from '@components/layout';
import { Permission } from '@components/permission/Permission';

import withAuthenticate from '@hocs/withAuthenticate';

import PublicOrder from '@views/public-order';
import Profile from '@views/profile/Profile';

import AuthLayout from './layout/AuthLayout';
import DashboardLayout from './layout/DashboardLayout';

const DashboardLayoutRequiredAuth = withAuthenticate(DashboardLayout);

const Login = lazy(() => import('@views/auth/Login'));
const Users = lazy(() => import('@views/users/Users'));
const Settings = lazy(() => import('@views/settings/Settings'));
const SettingWebsite = lazy(() => import('@views/settings/SettingWebsite'));
const SettingWebsiteCreate = lazy(() => import('@views/settings/SettingWebsiteCreate'));
const SettingWebsiteUpdate = lazy(() => import('@views/settings/SettingWebsiteUpdate'));
const Products = lazy(() => import('@views/products/Products'));
const Dashboard = lazy(() => import('@views/dashboard/Dashboard'));
const Orders = lazy(() => import('@views/orders'));
const ViewDashboard = lazy(() => import('@views/dashboard/ViewDashboard'));
const ProductEdit = lazy(() => import('@views/products/ProductEdit'));
const Customers = lazy(() => import('@views/customers/Customers'));
const Categories = lazy(() => import('@views/categories'));
const Attributes = lazy(() => import('@views/attributes'));
const Shops = lazy(() => import('@views/shops/Shops'));
const OrderLogs = lazy(() => import('@views/order-logs/LogsOrder'));
const OrderDetail = lazy(() => import('@views/order-detail'));
const Logs = lazy(() => import('@views/logs/Logs'));

const authRoutes = {
  path: '/auth',
  element: <AuthLayout />,
  children: [
    { index: true, element: <Navigate to="/auth/login" /> },
    { path: PATH_NAME.LOGIN, element: <Login /> },
    { path: '*', element: <NotFound /> },
  ],
};

const dashboardRoutes = {
  path: PATH_NAME.ROOT,
  element: <DashboardLayoutRequiredAuth />,
  children: [
    {
      index: true, element: <Permission
        roles={[ROLE.ADMIN, ROLE.SUPPER_ADMIN]}
        noAccess={
          <Navigate to={PATH_NAME.ORDERS} />
        }
      >
        <Navigate to={PATH_NAME.DASHBOARD} />
      </Permission>
    },
    { path: PATH_NAME.DASHBOARD, element: <Dashboard /> },
    { path: PATH_NAME.DASHBOARD_DETAIL, element: <ViewDashboard /> },
    { path: PATH_NAME.PRODUCTS, element: <Products /> },
    { path: PATH_NAME.CATEGORIES, element: <Categories /> },
    { path: PATH_NAME.ATTRIBUTES, element: <Attributes /> },
    { path: PATH_NAME.PRODUCT_CREATE, element: <ProductEdit /> },
    { path: PATH_NAME.PRODUCT_DETAIL, element: <ProductEdit /> },
    { path: PATH_NAME.USERS, element: <Navigate to={PATH_NAME.ADMIN_MANAGE} /> },
    { path: PATH_NAME.ADMIN_MANAGE, element: <Users /> },
    { path: PATH_NAME.CUSTOMER_MANAGE, element: <Customers /> },
    { path: PATH_NAME.ORDERS, element: <Orders /> },
    { path: PATH_NAME.ORDER_DETAIL, element: <OrderDetail /> },
    { path: PATH_NAME.SETTINGS_COMMON, element: <Settings /> },
    { path: PATH_NAME.STORES, element: <Shops /> },
    { path: PATH_NAME.SETTINGS_WEBSITE, element: <SettingWebsite /> },
    { path: PATH_NAME.SETTINGS_WEBSITE_BANNER_CREATE, element: <SettingWebsiteCreate /> },
    { path: PATH_NAME.SETTINGS_WEBSITE_BANNER_UPDATE, element: <SettingWebsiteUpdate /> },
    { path: PATH_NAME.ORDER_LOGS, element: <OrderLogs /> },
    { path: PATH_NAME.LOGS, element: <Logs /> },
    { path: '*', element: <NotFound /> },
  ],
};

const publicRoutes = {
  path: '/orders',
  element: <PublicOrder />,
};

const publicProfile = {
  path: '/users',
  element: <Profile isVerifyOTP />
};

const router = createBrowserRouter([dashboardRoutes, authRoutes, publicRoutes, publicProfile]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
