export const ROLE = {
  SUPPER_ADMIN: 'supper_admin',
  ADMIN: 'admin',
  VIEWER: 'viewer',
  STAFF: 'staff',
  SALES: 'sales',
  ZALO_CUSTOMER: 'zalo_customer',
  CUSTOMER: 'customer',
};

export const ROLES = ['supper_admin', 'admin', 'staff', 'viewer', 'sales', 'zalo_customer', 'customer'];
