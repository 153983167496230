import React from 'react';

import { StyledSelect } from '@styles/overrides';

import { useDistrictsByProvinceId } from '@api/locationApi';

export const DistrictSelect = ({ name, label, defaultValue, provinceId, ...props }) => {
  const { data, isLoading } = useDistrictsByProvinceId({ provinceId });
  console.log('DistrictSelect', provinceId);

  return (
    <StyledSelect
      {...props}
      name={name}
      loading={isLoading}
      options={data?.map(item => ({ label: item.name, value: item.id })) ?? []}
    />
  );
};
