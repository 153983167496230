import * as React from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';

import { Modal } from '@components/modal/Modal';

import { useResetFormOnCloseModal } from '@hooks/useResetFormOnCloseModal';

import { OTPForm } from './OTPForm';

export function OTPModal({
  error,
  onSubmit,
  isLoading = false,
  initialValues,
  ...props
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  return (
    <Modal
      title={intl.formatMessage({ id: 'common.modal.otpTitle' })}
      onOk={() => {
        form.validateFields().then(onSubmit);
      }}
      okButtonProps={{ loading: isLoading }}
      okText={intl.formatMessage({ id: 'common.btn.confirm' })}
      {...props}
    >
      <OTPForm form={form} initialValues={initialValues} onEnter={() => form.validateFields().then(onSubmit)} error={error} />
    </Modal>
  );
}
