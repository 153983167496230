import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import rootReducer from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    if (process.env.NODE_ENV === 'development') {
      const logger = createLogger({ collapsed: true });

      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [],
        },
      }).concat(logger);
    }

    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
      },
    });
  },
});
