import { useQuery, useMutation } from '@tanstack/react-query';
import * as _ from 'lodash';

import axiosClient from './axiosClient';

export const orderApi = {
  getAll(params) {
    const result = _.pickBy(params, value => value && value !== 'null');
    return axiosClient.get('/orders', { params: result });
  },
  getById(id) {
    return axiosClient.get(`/orders/${id}`);
  },
  create(data) {
    return axiosClient.post('/orders', data);
  },
  update(id, data) {
    return axiosClient.put(`/orders/${id}`, data);
  },
  updateStatus(id, data) {
    return axiosClient.patch(`/orders/${id}/status`, data);
  },
  updatePaymentStatus(data) {
    return axiosClient.patch(`/orders/${data.order_id}/payment-status`, data);
  },
  updateOrderAddress(id, data) {
    return axiosClient.patch(`/orders/address/${id}`, data);
  },
  updateOrderItem(id, data) {
    return axiosClient.put(`/orders/${id}`, data);
  },

  import(data) {
    return axiosClient.post('/orders/import', data, {
      timeout: 0,
    });
  },
  downloadCsvTemplate() {
    return axiosClient.get('/orders/csv-template', {
      responseType: 'blob',
    });
  },
  getDetail(params) {
    return axiosClient.get(`/public/orders/${params.code}`, { params });
  },
  exportExcel(params) {
    const result = _.pickBy(params, value => value && value !== 'null');
    return axiosClient.get('/orders/excel-export', {
      params: result,
      timeout: 10 * 60 * 1000,
      responseType: 'blob',
    });
  },
  getPublicDetail(params) {
    return axiosClient.get('/public/orders/detail', { params });
  },
  updateNoteAdmin(data) {
    return axiosClient.patch(`/orders/${data.id}/admin-note`, data);
  },
};

export const useOrders = ({ params, options } = {}) =>
  useQuery({
    ...options,
    refetchOnMount: false,
    queryKey: ['orders', params],
    queryFn: () => orderApi.getAll(params),
  });

export const useOrder = ({ id, options } = {}) =>
  useQuery({
    queryKey: ['orders', 'detail', id],
    queryFn: () => orderApi.getById(id),
    ...options,
  });

export const useCreateOrder = () =>
  useMutation({
    mutationFn: data => orderApi.create(data),
  });

export const useUpdateOrder = () =>
  useMutation({
    mutationFn: ({ id, ...data }) => orderApi.update(id, data),
  });

export const useUpdateOrderStatus = () =>
  useMutation({
    mutationFn: ({ id, status }) => orderApi.updateStatus(id, { status }),
  });

export const useImportOrder = () =>
  useMutation({
    mutationFn: orderApi.import,
  });

export const useDownloadCsvTemplate = () => useMutation({ mutationFn: orderApi.downloadCsvTemplate });

export const useGetOrderDetail = ({ params, options }) =>
  useQuery({
    queryKey: ['orders', 'detailByCode', params],
    queryFn: () => orderApi.getDetail(params),
    ...options,
  });

export const useGetPublicOrderDetail = ({ params, options }) =>
  useQuery({
    queryKey: ['orders', 'publicDetailByCode', params],
    queryFn: () => orderApi.getPublicDetail(params),
    ...options,
  });

export const useExportOrderExcel = () =>
  useMutation({
    mutationFn: orderApi.exportExcel,
  });

export const useUpdateOrderPayment = () => useMutation(orderApi.updatePaymentStatus);
export const useUpdateNoteAdmin = () =>
  useMutation({
    mutationFn: data => orderApi.updateNoteAdmin(data),
  });
