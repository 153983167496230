import React, { useEffect } from 'react';
import { Col, Row, Form } from 'antd';
import { useIntl } from 'react-intl';
import { useWatch } from 'antd/es/form/Form';

import { StyledButton, StyledForm, StyledFormItem, StyledInput, StyledInputTextArea } from '@styles/overrides';

import { DistrictSelect, ProvinceSelect, WardSelect } from '@components/form';

const serializeValues = initialValues => ({
  fullName: initialValues?.fullName,
  provinceId: initialValues?.provinceId,
  districtId: initialValues?.districtId,
  wardId: initialValues?.wardId,
  address: initialValues?.address,
});

export function ProfileForm({ initialValues = {}, onSubmit = () => {}, isAddNew }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(serializeValues(initialValues));
  }, []);

  const provinceId = useWatch(['provinceId'], { form, preserve: true });
  const districtId = useWatch(['districtId'], { form, preserve: true });

  const handleSubmit = values => {
    initialValues?.id ? onSubmit({ ...values, id: initialValues?.id }) : onSubmit(values);
  };

  const handleChangeProvince = () => {
    form.setFieldValue(['districtId'], undefined);
    form.setFieldValue(['wardId'], undefined);
  };

  const handleChangeDistrict = () => {
    form.setFieldValue(['wardId'], undefined);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      onFinish={handleSubmit}
      form={form}
      initialValues={serializeValues(initialValues)}
    >
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <StyledFormItem
            name="fullName"
            label={intl.formatMessage({
              id: 'views.customers.form.label.fullName',
            })}
            rules={[{ required: true }]}
          >
            <StyledInput
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.fullName',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.customers.form.label.provinceId',
            })}
            name={['provinceId']}
            rules={[{ required: true }]}
          >
            <ProvinceSelect
              onChange={handleChangeProvince}
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.provinceId',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.customers.form.label.districtId',
            })}
            name={['districtId']}
            rules={[{ required: true }]}
          >
            <DistrictSelect
              provinceId={provinceId}
              onChange={handleChangeDistrict}
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.districtId',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.customers.form.label.wardId',
            })}
            name={['wardId']}
            rules={[{ required: true }]}
          >
            <WardSelect
              districtId={districtId}
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.wardId',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.customers.form.label.address',
            })}
            name={['address']}
            rules={[{ required: true }]}
          >
            <StyledInputTextArea
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.address',
              })}
              autoSize={{ minRows: 3 }}
            />
          </StyledFormItem>
        </Col>
      </Row>

      <StyledFormItem style={{ textAlign: 'center' }}>
        <StyledButton type="primary" htmlType="submit">
          {intl.formatMessage({
            id: `common.btn.${isAddNew ? 'addNew' : 'update'}`,
          })}
        </StyledButton>
      </StyledFormItem>
    </StyledForm>
  );
}
