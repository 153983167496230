import React from 'react';
import { InfoIcon } from '@assets/icons';

import { StyledSpace, StyledTypographyParagraph } from '@styles/overrides';

export const CollapseHeader = ({ title }) => (
  <StyledSpace size={10}>
    <InfoIcon style={{ fontSize: 24, color: 'var(--secondary800)' }} />
    <StyledTypographyParagraph style={{ fontWeight: 600, color: 'var(--secondary800)' }}>
      {title}
    </StyledTypographyParagraph>
  </StyledSpace>
  );
