import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoading = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const Loading = () => (
  <StyledLoading>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} />
  </StyledLoading>
  );
