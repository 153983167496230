import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';

import { StyledForm, StyledFormItem, StyledInput } from '@styles/overrides';

import { CardWrapper } from '@components/layout';

export function OTPForm({ initialValues, onEnter, error, ...props }) {
  const intl = useIntl();

  useEffect(() => {
    props.form?.setFieldsValue(initialValues);
  }, [initialValues, props.form]);

  return (
    <CardWrapper>
      <StyledForm layout="vertical" size="large" {...props}>
        <Row gutter={[32, 0]}>
          <Col span={24}>
            <StyledFormItem
              label={intl.formatMessage({
                id: 'components.otp.form.label.code',
              })}
              name="code"
              rules={[{ required: true }]}
            >
              <StyledInput
                onPressEnter={() => onEnter()}
                placeholder={intl.formatMessage({
                  id: 'components.otp.form.placeholder.code',
                })}
              />
            </StyledFormItem>
          </Col>
        </Row>
        {!!error && error?.response?.data?.message ? (
          <span style={{ color: 'red' }}>{error?.response?.data?.message}</span>
        ) : null}
      </StyledForm>
    </CardWrapper>
  );
}
