import React from 'react';
import styled from 'styled-components';
import { capitalizeLetter } from '@common/utils/stringUtils';

const StyledUserRole = styled.div`
  font-size: 14px;
  display: inline-block;
  color: var(--gray700);
  border: 1px solid var(--suportcolormint300);
  border-radius: 24px;
  padding: 7px 12px;
  background-color: var(--suportcolormint100);
  height: 32px;
`;

export function UserRole({ role }) {
  return <StyledUserRole>{capitalizeLetter(role)}</StyledUserRole>;
}
